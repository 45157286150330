@mixin caret-down {
  content: '\f107';
}

@mixin caret-up {
  content: '\f106';
}

@mixin caret-right {
  content: '\f105';
}

@mixin caret-left {
  content: '\f104';
}

@mixin caret($direction: down) {
  @if $enable-caret {
    &::after {
      display: inline-block;
      margin-left: $caret-spacing;
      vertical-align: $caret-vertical-align;
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      // font-size: 1em;
      border: none;
      content: "";
      @if $direction == down {
        @include caret-down;
      } @else if $direction == up {
        @include caret-up;
      } @else if $direction == right {
        @include caret-right;
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $caret-spacing;
        vertical-align: $caret-vertical-align;
        content: "";
        @include caret-left;
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
