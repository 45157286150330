.btn:not([class^="btn-outline-"]):not([class*=" btn-outline-"]) {
  border: none;
}

.btn[class^="btn-outline-"][class*=" btn-outline-"] {
  background: yellow;
}

.btn-unstyled {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  text-decoration: underline;
}

.btn-unstyled:focus,
.btn-unstyled:hover {
  outline: none;
  text-decoration: none;
}

.btn.btn-light {
  background: none;
}

.btn.btn-secondary {
  @include gradient-y(map-get($theme-gradients, 'secondary')...);
}

.btn-input {
  height: $input-height;
  padding: map-get($spacers, 1) $btn-padding-x;
}
