@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import '~scss/custom';


@include media-breakpoint-up(md) {
  .container {
    height: 85px;
  }
}

.logo {
  max-height: 55px;

  @include media-breakpoint-down(md) {
    height: 30px;
  }
}
