h1 {
  @extend h1;
  font-weight: $h1-font-weight;
  line-height: $h1-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h1-sm-font-size;
    line-height: $h1-sm-line-height;
  }
}

h2 {
  @extend h2;
  font-weight: $h2-font-weight;
  line-height: $h2-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h2-sm-font-size;
    line-height: $h2-sm-line-height;
  }
}

h3 {
  @extend h3;
  font-weight: $h3-font-weight;
  line-height: $h3-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h3-sm-font-size;
    line-height: $h3-sm-line-height;
  }
}

h4 {
  @extend h4;
  font-weight: $h4-font-weight;
  line-height: $h4-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h4-sm-font-size;
  }
}

h5 {
  @extend h5;
  font-weight: $h5-font-weight;
  line-height: $h5-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h5-sm-font-size;
  }
}

h6 {
  @extend h6;
  font-weight: $h6-font-weight;
  line-height: $h6-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h6-sm-font-size;
  }
}

label {
  display: inline-block;
  margin-bottom: $label-margin-bottom;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  color: $label-color;
}
