.hero-curved {
  background: none;
  position: relative;
  overflow-x: hidden;

  .container {
    overflow: hidden;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 40px !important;
  }
}

.hero-curved:after {
  content: '';
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 100% 100%;
  position: absolute;
  @include gradient-x(map-get($theme-gradients, "primary")..., $start-percent: 10%, $end-percent: 90%);
  right: -10%;
  left: -10%;
  top: 0;
  height: calc(100% - 6px);
  z-index: -1000;

  @include media-breakpoint-down(md) {
    @include gradient-x(map-get($theme-gradients, "primary")..., $start-percent: 30%, $end-percent: 70%);
    right: -80%;
    left: -80%;
  }
}

@include media-breakpoint-up(md) {
  .hero-curved.hero-425:after {
    height: 425px !important;
  }
}

@include media-breakpoint-down(md) {
  .hero .card {
    border-radius: 0;
  }
}

.hero-curved.h-350:after,
.hero-curved .h-350 {
  height: 350px !important;
}

@mixin hero-bg($url) {
  &:after {
    background:
      linear-gradient(rgba($primary, .8),rgba($primary, .8)),
      url($url);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.hero-motor {
  @include hero-bg('images/motor_insurance_hero_bg.jpg');
}

.hero-home {
  @include hero-bg('images/home_insurance_hero_bg.jpg');
}

.hero-group-health {
  @include hero-bg('images/group_health_hero_bg.jpg');
}

.hero-group-life {
  @include hero-bg('images/group_life_hero_bg.jpg');
}

.hero-small-business {
  @include hero-bg('images/business_hero_bg.jpg');
}