@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem-calc($size) {
  // $baseSize: parseInt($font-size-base) * 16;
  // $remSize: parseInt($size) / $baseSize;
  $remSize: parseInt($size) / 16;
  @return #{$remSize}rem;
}
