.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-38 {
  font-size: 38px !important;
}

@include media-breakpoint-down(md) {
  .font-size-sm-12 {
    font-size: 12px !important;
  }

  .font-size-sm-14 {
    font-size: 14px !important;
  }

  .font-size-sm-16 {
    font-size: 16px !important;
  }

  .font-size-sm-18 {
    font-size: 18px !important;
  }

  .font-size-sm-22 {
    font-size: 22px !important;
  }

  .font-size-sm-24 {
    font-size: 24px !important;
  }

  .font-size-sm-26 {
    font-size: 26px !important;
  }

  .font-size-sm-28 {
    font-size: 28px !important;
  }
}

.text-sm-primary {
  @include media-breakpoint-down(md) {
    color: theme-color("primary");
  }
}

.text-subtle {
  color: $gray-500 !important;
}

.font-weight-heavy {
  font-weight: $font-weight-heavy !important;
}
