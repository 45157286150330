@font-face {
    font-family: 'Mark Pro';
    src: url('/fonts/MarkPro/MarkPro-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('/fonts/MarkPro/MarkPro.otf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('/fonts/MarkPro/MarkPro-Medium.otf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('/fonts/MarkPro/MarkPro-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mark Pro';
    src: url('/fonts/MarkPro/MarkPro-Heavy.otf');
    font-weight: 900;
    font-style: normal;
}


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Import custom mixins and functions before everything else
@import "scss/mixins/buttons";
@import "scss/mixins/gradients";
@import "scss/mixins/caret";
@import "scss/mixins/alert";
@import "scss/functions";

@import "scss/custom";

@import "~bootstrap/scss/print";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";


// Import component specific overrides and custom components
@import "scss/box-shadow";
@import "scss/buttons";
@import "scss/close";
// @import "scss/cards";
@import "scss/column-counts";
@import "scss/custom-forms";
@import "scss/dropdown";
@import "scss/forms";
@import "scss/gradients";
@import "scss/hero";
@import "scss/icons";
@import "scss/input-group";
@import "scss/lists";
@import "scss/modal";
@import "scss/nav";
@import "scss/overflow";
@import "scss/reboot";
@import "scss/text";


@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
