ul.list-check {
  list-style: none;

  li:before {
    content: '\f00c';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-left: -32px;
    margin-right: 16px;
    color: $green-secondary;
  }
}

ul.list-dash {
  li:before {
    content: '-';
    margin-right: 5px;
  }
}

ul.list-col-2 {
  columns: 2;
}
